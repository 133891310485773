import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO 
      lang="END"
      title="404 Tape Me"
      keywords={['badoo, tinder, TapeMe, love, person, geart, sex, date, Dating, datingsite, findlove, tapeme, voicemessage']}/>
    <div className="container">
      <div style={{marginBottom: "6%", marginTop: "6%"}}>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
